import React from 'react'
import { Helmet } from "react-helmet";
import Footer from './Footer';
import '../Style/Portfolio.css'
import { useMobileHooks } from '../hooks/useMobileHooks';
import { Logo } from './Logo/Logo';
import { Navigate, useNavigate, useParams } from 'react-router-dom';


export default function SubmitApplicant() {
    const navigate = useNavigate()
    const {mobileView, currentWidth} = useMobileHooks()
    const { applicantId } = useParams();
    console.log(applicantId,"applicantId")
  
  
    
    const logoWidth = mobileView ? "200px" : "300px"
  return (
    <>
      <Helmet>
      <title>Our Success Applicants | Spangles Infotech</title>
      <meta name="description" content="Our portfolio showcases a diverse range of successful projects, highlighting our expertise in software development, web solutions, and IT services. We have consistently delivered innovative and tailored solutions, driving success for businesses across various industries." />
      <meta name="keywords" content="Web design services, Web development company, Best website designers, Web app development companies, Custom web app development, Top mobile app development companies, Ecommerce web development services, Software testing firms" />
    </Helmet>
    <div className='Portfolio'>
        <div className='d-flex justify-content-center flex-column align-items-center my-3'>
            <div className='my-3'>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M720-120H280v-520l280-280 50 50q7 7 11.5 19t4.5 23v14l-44 174h258q32 0 56 24t24 56v80q0 7-2 15t-4 15L794-168q-9 20-30 34t-44 14Zm-360-80h360l120-280v-80H480l54-220-174 174v406Zm0-406v406-406Zm-80-34v80H160v360h120v80H80v-520h200Z"/></svg>
            </div>
            <h3 className='my-3'>
                Submitted Successfully
            </h3>
            <h6 className='my-3'> We appreciate you for completing the form</h6>
            <div className='my-3'>
          <Logo className="my-3" logoWidth={logoWidth}/>

            </div>

            <div className='border border-2 p-3 rounded-5'>
                Your Application Number is : <span> {applicantId}</span>

            </div>
            <div className='d-flex justify-content-center flex-column align-items-center my-3  '>
                <button onClick={()=>navigate('/')} className='p-2  px-3 rounded-3'> Done  </button>

            </div>

        </div>

    </div>


            <Footer />
        </>
  )
}
