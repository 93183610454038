import React, { useState } from 'react';
import './SideBar.css';
import { useNavigate } from 'react-router-dom';
import RegistrationForm from './RegistrationForm';

const Sidebar = ({ isOpen, onClose }) => {


  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button onClick={onClose} className="sidebar-close-button">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
          <path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z" />
        </svg>
        Back
      </button>

      <div className="sidebar-content">
        <h2 className='d-flex justify-content-center'>SPANGLES Application Form</h2>
    <RegistrationForm  isOpen={isOpen} onClose={onClose}/>
      </div>
    </div>
  );
};

export default Sidebar;
