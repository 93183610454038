import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./SideBar";
import { useMobileHooks } from "../hooks/useMobileHooks";
import { useLocation, useNavigate } from "react-router-dom";

export const EventIcon = () => {
  const [isHovered, setIsHovered] = useState(false);
  const { mobileView } = useMobileHooks();
  const navigate = useNavigate();



  console.log(mobileView,"mobileView")
  const containerRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const location = useLocation();
  const path = location.pathname.startsWith("/registration")
  console.log(path,"path")

  const handleSidebarOpen = () => {
    if(mobileView){
      navigate(`/registration`)

    }else{
      
      setIsSidebarOpen(true);
    }
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsHovered(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  return (
    <>
    {path == false ? (
    <div className="event-icon-wrapper"> 
      <div
        className="event-icon-container"
        ref={containerRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div onClick={handleSidebarOpen} className={`  event-icon ${isHovered ? "event-icon-expanded rounded-2" : ""}`}>
          <div className="d-flex  justify-contents-center align-items-center  text-bg-light border rounded-2 p-3 "  >
          <svg xmlns="http://www.w3.org/2000/svg" height="44px" viewBox="0 -960 960 960" width="44px" fill="current"><path d="M240-160q-33 0-56.5-23.5T160-240q0-33 23.5-56.5T240-320q33 0 56.5 23.5T320-240q0 33-23.5 56.5T240-160Zm0-240q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm0-240q-33 0-56.5-23.5T160-720q0-33 23.5-56.5T240-800q33 0 56.5 23.5T320-720q0 33-23.5 56.5T240-640Zm240 0q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Zm240 0q-33 0-56.5-23.5T640-720q0-33 23.5-56.5T720-800q33 0 56.5 23.5T800-720q0 33-23.5 56.5T720-640ZM480-400q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm40 240v-123l221-220q9-9 20-13t22-4q12 0 23 4.5t20 13.5l37 37q8 9 12.5 20t4.5 22q0 11-4 22.5T863-380L643-160H520Zm300-263-37-37 37 37ZM580-220h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19Z"/></svg> 

          </div>
          <p className="event-icon-text" style={{ opacity: isHovered ? 1 : 0.5 }}>
          {isHovered ? "Registration Form" :""}
          </p>
        </div>
      </div>
      <div
        className={`register-text ${isHovered ? "register-text-hidden" : ""}`}
      >
        Register Now
      </div>

      <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarClose} />
    </div>
    ) : <>
    </>

     }
    </>
  );
};