import React from 'react'
import Footer from '../Componets/Footer'
import RegistrationForm from '../Componets/RegistrationForm'

export default function Registration() {
  return (
    <>
    
    <div style={{zIndex:22222}} className='p-3'>

    <RegistrationForm/>
    </div>

    <Footer/>
    </>
  )
}
