import React from "react";
import Logo from "../image/Spangles White 1.png";
import "../Style/Footer.css";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaPinterestSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaSquareXTwitter } from "react-icons/fa6";

function Footer() {

  const address = ["389, Joe Daniel st, Near palpannai,", "Nagercoil, KK District -  629003 "]
  const quickLinks = [
    {
      "path":"/",
      "name":"Home"
    },
    {
      "path":"Aboutus",
      "name":"About Us"
    },
    {
      "path":"Services",
      "name":"Services"
    },
    {
      "path":"Ourproducts",
      "name":"Our Products"
    },
    {
      "path":"Portfolio",
      "name":"Portfolio"
    },
    {
      "path":"Blogs",
      "name":"Blogs"
    },
    {
      "path":"Contact",
      "name":"Contact us"
    }
  ] 
  const serviceLinks = [
    {
      "path":"Websitedesigningdevelopment",
      "name":"Website Designing"
    },
    {
      "path":"Webapplication",
      "name":"Web Application"
    },
    {
      "path":"Mobileapp",
      "name":"Mobile Application"
    },
    {
      "path":"Testing",
      "name":"Software Testing"
    },
    {
      "path":"UIUXdesigning",
      "name":"UI/UX Designing"
    },
    {
      "path":"Ecommerce",
      "name":"E-Commerce Development"
    },
  ] 
  const contactInfo = [
    {
      name:"Office No",
      value:"+91 8870731214",
    },
    {
      name:"Mail",
      value:"info@spanglesinfotech.com",
    },
    {
      name:"Email",
      value:"spanglesinfotech@gmail.com "
    },
  ]
  const socialInfo = [
    {
      path: "https://www.instagram.com/spangles_infotech/",
      icon: <FaInstagramSquare />,
      name: "instagram"
    },
    {
      path: "https://x.com/I42379Spangles",
      icon: <FaSquareXTwitter />,
      name: "twitter"
    },
    {
      path: "https://www.facebook.com/SpanglesInfotech",
      icon: <FaFacebookSquare />,
      name: "facebook"
    },
    {
      path: "https://www.linkedin.com/in/spangles-infotech-8a6a17298/",
      icon: <FaLinkedin />,
      name: "linkedin"
    },
    {
      path: "https://www.youtube.com/channel/UCvRiHg7KK1wShmDNXZeQy_w/",
      icon: <FaYoutubeSquare />,
      name: "youtube"
    },
    {
      path: "https://in.pinterest.com/infotech1131/",
      icon: <FaPinterestSquare />,
      name: "pinterest"
    }
  ];

  return (
      <div className="footer" >
        <div className="footer-top">
          <div className="footer-cont" >
            <div className="d-flex flex-column justify-content-start gap-4 foot-info">
              <img src={Logo} alt="Logo" className="img"  />
              <div>
                <div style={{ color: "white", textAlign: "start" }} >
                  <p className="m-0 text" style={{color: 'white',textAlign: 'start', fontSize:"15px" }}>
                    In the words of Spangles Infotech, unbelievable results are
                    possible when marketing, technology, and creativity come
                    together through strategic planning. We constantly prioritize
                    business growth and conversions while acting in our client’s
                    best interests.</p>
                </div>
              </div>
              <div className="d-flex flex-column align-items-start gap-4" >
                <p className="m-0" style={{ color: "white", fontSize:"23px", fontWeight:"600" }}>Office Address</p>
                <div className="d-flex flex-column gap-2 align-items-start">
                  {
                    address.map((item, index) => (
                      <p className="m-0 p-0" style={{ color: "white", marginTop: '30px' }} key={index}>{item}</p>
                    ))
                  }
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-start gap-4 foot-quick-links"
              style={{ textAlign: 'start'}}
            >
              <p className="m-0" style={{ color: "white", fontSize:"23px", fontWeight:"600" }}>Quick links</p>
              <div className="d-flex flex-column gap-3 ">
                {
                  quickLinks.map((item)=>(
                    <a href={item.path} style={{ textDecoration: 'none' }} key={item.name}>
                      <p className="m-0 links">{item.name}</p>
                    </a>
                  ))
                }
              </div>

            </div>
          </div>
          <div className="footer-second-cont">
            <div className="d-flex flex-column justify-content-start align-items-start gap-4 second-col ">
              <p className="m-0" style={{ color: "white", fontSize:"23px", fontWeight:"600" }}> Services</p>
              <div className="d-flex flex-column gap-3 align-items-start ">
                {
                  serviceLinks.map((item)=>(
                    <a href={item.path}  style={{ textDecoration: 'none' }} key={item.name}>
                      <p className="m-0 links">{item.name}</p>
                    </a>
                  ))
                }
              </div>
            </div>
            <div className="d-flex flex-column justify-content-start align-items-start gap-4 info-col">
              <p className="m-0" style={{ color: "white", fontSize:"23px", fontWeight:"600" }}>Let's Talk</p>
              <div className="d-flex flex-column gap-2 align-items-start " style={{width:"auto"}}> 
                {
                  contactInfo.map((item)=>(
                    <a href={item.name === "Office No" ?"tel:":"mailto:"+item.value} style={{ color: "white", fontWeight:"600", textDecoration:"none" }} key={item.name}>{item.name} : {item.value}</a>
                  ))
                }
              </div>
              <div className="d-flex flex-column gap-3 align-items-start">
                <p className="m-0" style={{ color: "white", fontSize:"23px", fontWeight:"600" }}>Get Connect With Us</p>
                  <div className="d-flex flex-row gap-2">
                    {
                      socialInfo.map((item)=>(
                        <Link to={item.path} key={item.name}>
                          <span className="icons1" style={{ color: "white" }}>
                            {item.icon}
                          </span>
                        </Link>
                      ))
                    }
                  </div>
              </div>
            </div>
          </div>

        </div>
        <div style={{backgroundColor:"#7C7C7C", height:"1px",width:"100%"}}></div>
        <div className="footer-bottom-content">
          <h6 className="m-0" style={{ color: "#CECECE", alignItems: 'center', textAlign: 'center' }}>
            2024 © spanglesinfotech.com. All rights reserved.
          </h6>
          <div className="privacy-cont" >
            <a href="/Privacy Policy" style={{ color: "white", fontWeight:"400", textDecoration:"none" }}  ><p>Privacy Policy</p></a>
            <a href="/Terms and Conditions" style={{ color: "white", fontWeight:"400", textDecoration:"none" }} ><p>Terms and Conditions</p></a>
          </div>
        </div>
      </div>
  );
}

export default Footer;






