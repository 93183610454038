import React, { useState } from "react";
import Sv from "../image/tm.png";
import sv from "../image/tm2.png";
import "../Style/Services.css";
import Footer from "../Componets/Footer";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { features, howWorks, meaning, whyNeed } from "../utils/variable/tuitionVariable";
import { Testimonial } from "../Componets/Testimonial";

function Tuitionmanagement() {
  
  const navigate = useNavigate();

  const Contact = () => {
    navigate(`/Contact`);
  };
  
  return (
    <div className="Tuitionmanagement" >
      <Helmet>
        <title> Best Tuition management Software | Spangles Infotech </title>
        <meta
          name="description"
          content="Spangles Infotech offers advanced tuition management software that streamlines administrative tasks, student enrollment, and fee collection. Our solution enhances communication and efficiency for educational institutions, ensuring a smooth and organized operation. "
        />
        <meta
          name="keywords"
          content="Tuition management software, Tuition management systems, tuition centre management system tutoring center management software "
        />
      </Helmet>
      <Container>
        <div
          style={{ textAlign: "center", marginTop: "10%", marginBottom: "10%" }}
        >
          <h1 className="text1">
            <b>Tuition Management Software</b>
          </h1>
          <p className="mt-5" style={{ width: "100%", fontSize: "20px" }}>
            Empower your institution with seamless tuition management:
            simplifying payments, optimizing financial aid, and ensuring student
            success.
          </p>
          <p style={{ width: "90%" }}></p>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <img
              src={Sv}
              className="mb-3"
              alt="web designing"
              style={{ width: "80%" }}
            />
          </div>
          <div className="col-sm-6 ">
            <p style={{ marginTop: "20px", width: "90%" }}>
              {meaning}
            </p>
            <button
              type="button"
              className="btn btn-outline-custom"
              style={{ width: "200px", padding: "10px" }}
            >
              <a
                href="Contact"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Contact Us
              </a>
            </button>
          </div>
        </div>
      </Container>

      <div
        style={{ backgroundColor: "#345261", height: "50%", marginTop: "10%" }}
      >
        <Container>
          <div className="d-flex justify-content-between p-3">
            <h4 style={{ color: "white" }} className="mt-1">
              Searching for tuition management software to improve
              administrative efficiency?{" "}
            </h4>

            <a
              href="Contact"
              style={{ textDecoration: "none", color: "#345261" }}
            >
              <button
                type="button"
                class="btn btn-light custom-buttons "
                style={{ width: "300px", padding: "10px", marginLeft: "3%" }}
              >
                Schedule a demo
              </button>
            </a>
          </div>
        </Container>
      </div>

      <Container>
        <div>
          <h2
            style={{
              textAlign: "center",
              color: "#345261",
              marginTop: "10%",
              marginBottom: "5%",
            }}
          >
            {" "}
            <b>Why Tuition Management Software? </b>
          </h2>
          <p style={{ textAlign: "center", marginBottom: "5%" }}>
            {whyNeed}
          </p>
          <h2
            style={{
              textAlign: "center",
              color: "#345261",
              marginBottom: "10%",
            }}
          >
            {" "}
            <b>Essential Features of Tuition Management Software :</b>
          </h2>
        </div>

        {
            features.map((feature)=>(
                <div className={`${feature?.id % 2 === 0  ? "boxborder-gradient-8": "boxborder-gradient-7"}`} key={feature?.id}> 
                <h5>
                    <b className={`header ${feature?.id % 2 === 0  ? "header-right": ""}`}>{feature?.title} :</b>
                </h5>
                <p className={`description ${feature?.id % 2 === 0 ? "description-right": ""}`} style={{ marginLeft: feature.id % 2 !== 0 ? "5%" : "0%", marginRight:feature.id % 2 === 0 ? "5%" : "0%" }}>
                    {feature?.content}
                </p>
                <img src={require(`../../src/image/${feature?.img}.png`)} className={`image ${feature.id % 2 === 0 ? "image-right": "image-left" }`} alt={feature.title} />
                </div>
            ))
        }

        <div className="row" style={{ marginTop: "10%" }}>
          <div className="col-sm-7 ">
            <h2 style={{ textAlign: "start", color: "#345261" }}>
              <b>How Tuition Management Software Works?</b>
            </h2>
            <p className="mt-3">
              {howWorks}
            </p>
            <button
              type="button"
              className="btn btn-outline-custom"
              style={{ width: "200px", padding: "10px" }}
            >
              <a
                href="Contact"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Contact Us To Buy Now
              </a>
            </button>
          </div>
          <div className="col-sm-5">
            <img
              src={sv}
              className="mb-3"
              alt="web designing"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <Testimonial />
      </Container>

      <Footer />
    </div>
  );
}

export default Tuitionmanagement;
