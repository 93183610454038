import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import Footer from './Footer';
import '../Style/Gallery.css';
import request from "../request";
import { Spinner } from 'react-bootstrap';
import removeIcon from "../image/remove.png"
import ReactPlayer from 'react-player';


const Baseurl = process.env.REACT_APP_BACKEND_API_URL;

function Gallery() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [galleryData, setGalleryData] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    fetchGalleryData();
  }, []);

  const fetchGalleryData = async () => {
    try {
      setIsLoading(true)
      const response = await request.get("/api/gallery/list");
      if (response.data && response.data.galleryData) {
        setGalleryData(response.data.galleryData);
      } else {
        setError("No gallery data found");
      }
    } catch (error) {
      console.error("Error fetching gallery data:", error);
      setError("Failed to fetch gallery data. Please try again later.");
    }finally{
      setIsLoading(false)
    }
  };

  const isMimeType = (item)=>{
    return item.mimetype && item.mimetype.startsWith('image') 
  }

  const handleOpenModal = (url)=>{
    setShowImage(!showImage)
    setSelectedImage(url)
  }

  useEffect(() => {
    const disableRightClick = (e) => {
      e.preventDefault();
    };
    window.addEventListener("contextmenu", disableRightClick);
    return () => {
      window.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Spangles Infotech Gallery</title>
        <meta name="description" content="Spangles Gallery showcases a vibrant collection of personal and collaborative moments captured within our gallery space. This gallery section offers a glimpse into our community events, artist interactions, and behind-the-scenes activities, reflecting the dynamic spirit of Spangles Gallery." />
        <meta name="keywords" content="Portfolio Showcase, Visual Journey, Project Highlights, Success Stories, Innovative Solutions, Technology Showcase" />
      </Helmet>
      <div className={`Gallery`}>
        <div className='gallery-section'>
          <h1 className="gallery-title m-0">
            <b>Spangles Gallery </b>
            {/* <span style={{fontWeight:"400"}}>Gallery</span> */}
          </h1>
          {error && <p>{error}</p>}
          { isLoading &&
            <div className="d-flex align-items-center justify-content-center">
                <Spinner />
              </div>
          }
          <div className='masonry-layout-cont'>
            { !isLoading && galleryData.map((item) => (
              <div key={item._id} className='masonry-item' onClick={()=>handleOpenModal(isMimeType(item) && `${Baseurl}/${item.path}`)}>
                { isMimeType(item) ? (
                  <img
                    src={`${Baseurl}/${item.path}`}
                    alt={item.name || 'Gallery Image'}
                    className='gallery-image'
                    style={{ objectFit: 'cover', height: 'auto' }}
                  />
                )
                :
                 item.mimetype && item.mimetype.startsWith("video") 
                ?
                  <ReactPlayer 
                    url={`${Baseurl}/${item.path}`}
                    controls={true}
                    loop
                    width={"100%"} height={"100%"}
                    className="gallery-video"
                    light={`${Baseurl}/${item?.thumbnailPath}`}
                  />
                 : (
                  <iframe
                    src={`${item.path}?rel=0`}
                    title={item.name || 'Gallery Video'}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ height: '400px', width: '100%' }}
                  ></iframe>
                )}
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Gallery;
