import React from 'react'
import '../Style/Portfolio.css'
import GP8 from '../image/12.jpg';
import GP9 from '../image/OZI9ES2.jpg';
import GP10 from '../image/19.jpg';
import GP11 from '../image/20.jpg';
import GP12 from '../image/201.jpg';
import GP13 from '../image/22.jpg';
import GP14 from '../image/OW9BQF1.jpg';
import GP15 from '../image/OZI9ES1.jpg';
import Footer from '../Componets/Footer';
import { Helmet } from "react-helmet";
import { Works } from './Works';

function Portfolio() {
  
    return (
<>
      <Helmet>
      <title>Our Portfolio | Spangles Infotech</title>
      <meta name="description" content="Our portfolio showcases a diverse range of successful projects, highlighting our expertise in software development, web solutions, and IT services. We have consistently delivered innovative and tailored solutions, driving success for businesses across various industries." />
      <meta name="keywords" content="Web design services, Web development company, Best website designers, Web app development companies, Custom web app development, Top mobile app development companies, Ecommerce web development services, Software testing firms" />
    </Helmet>
        <div className='Portfolio'>
            <div className='container' style={{ textAlign: 'center', marginTop: '100px',marginBottom:'10%' }}>
            <Works isProduct={true} />
            </div>
            <Footer />
        </div>
        </>
    )
}

export default Portfolio