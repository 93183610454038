import React, { useState } from 'react'
import axios from 'axios'; 
import './SideBar.css';
import { useNavigate } from 'react-router-dom';
import { useMobileHooks } from '../hooks/useMobileHooks';
import { URL } from '../App';



export default function RegistrationForm({ isOpen, onClose }) {
    const navigate = useNavigate();
  const { mobileView } = useMobileHooks();

    const [formData, setFormData] = useState({
      name: '',
      email: '',
      mobile_number: '',
      college_name: '',
      position_applied_for: '',
      preferred_job_location: '',
      willing_to_relocate: '',
      interested_in: ''
    });
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const requiredFields = Object.keys(formData).filter(key => !formData[key]);
      if (requiredFields.length) {
        alert(`Please fill in all required fields: ${requiredFields.join(', ')}`);
        return;
      }

      try {
        const response = await axios.post(`${URL}/api/spanglesApplicant/apply`, formData);   // change this to dynamic
        setFormData({
          name: '',
          email: '',
          mobile_number: '',
          college_name: '',
          position_applied_for: '',
          preferred_job_location: '',
          willing_to_relocate: '',
          interested_in: ''
        });
        if(response.data.spanglesApplicantId){
            navigate(`/success/${response.data.spanglesApplicantId}`)

            if(mobileView){
                // navigate(`/registration`)
                console.log(mobileView)
          
              }else{
                  
                  onClose();
              }
  
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
  
  
    };


  return (
    <form className="application-form" onSubmit={handleSubmit}>
    {[
      { label: "Name", id: "name", type: "text" },
      { label: "Email", id: "email", type: "email" },
      { label: "Mobile Number", id: "mobile_number", type: "text" },
      { label: "College Name", id: "college_name", type: "text" },
      { label: "Position Applied For", id: "position_applied_for", type: "text" },
    ].map((field) => (
      <div className="form-group" key={field.id}>
        <label htmlFor={field.id}>{field.label} <span className='redstar'>*</span></label>
        <input 
          type={field.type}
          id={field.id}
          name={field.id}
          value={formData[field.id]}
          onChange={handleChange}
          required
        />
      </div>
    ))}

    <div className="form-group">
      <label htmlFor="preferred_job_location">Preferred job location  <span className='redstar'>*</span></label>
      <select
        id="preferred_job_location"
        name="preferred_job_location"
        value={formData.preferred_job_location}
        onChange={handleChange}
        required
      >
<option value=""></option>
<option value="Ariyalur">Ariyalur</option>
<option value="Chengalpattu">Chengalpattu</option>
<option value="Chennai">Chennai</option>
<option value="Coimbatore">Coimbatore</option>
<option value="Cuddalore">Cuddalore</option>
<option value="Dharmapuri">Dharmapuri</option>
<option value="Dindigul">Dindigul</option>
<option value="Erode">Erode</option>
<option value="Kallakurichi">Kallakurichi</option>
<option value="Kanchipuram">Kanchipuram</option>
<option value="Kanyakumari">Kanyakumari</option>
<option value="Karur">Karur</option>
<option value="Krishnagiri">Krishnagiri</option>
<option value="Madurai">Madurai</option>
<option value="Mayiladuthurai">Mayiladuthurai</option>
<option value="Nagapattinam">Nagapattinam</option>
<option value="Namakkal">Namakkal</option>
<option value="Perambalur">Perambalur</option>
<option value="Pudukkottai">Pudukkottai</option>
<option value="Ramanathapuram">Ramanathapuram</option>
<option value="Ranipet">Ranipet</option>
<option value="Salem">Salem</option>
<option value="Sivaganga">Sivaganga</option>
<option value="Tenkasi">Tenkasi</option>
<option value="Thanjavur">Thanjavur</option>
<option value="Theni">Theni</option>
<option value="Thiruvallur">Thiruvallur</option>
<option value="Thiruvarur">Thiruvarur</option>
<option value="Thoothukudi (Tuticorin)">Thoothukudi (Tuticorin)</option>
<option value="Tiruchirappalli">Tiruchirappalli</option>
<option value="Tirunelveli">Tirunelveli</option>
<option value="Tirupathur">Tirupathur</option>
<option value="Tiruppur">Tiruppur</option>
<option value="Vellore">Vellore</option>
<option value="Viluppuram">Viluppuram</option>
<option value="Virudhunagar">Virudhunagar</option>
      </select>
    </div>

    <div className="form-group">
      <label htmlFor="willing_to_relocate">Willing to relocate <span className='redstar'>*</span></label>
      <select
        id="willing_to_relocate"
        name="willing_to_relocate"
        value={formData.willing_to_relocate}
        onChange={handleChange}
        required
      >
        <option value=""></option>
        <option value="yes">Yes</option>
        <option value="no">No</option>
      </select>
    </div>

    <div className="form-group">
      <label htmlFor="interested_in">Interested in <span className='redstar'>*</span></label>
      <select
        id="interested_in"
        name="interested_in"
        value={formData.interested_in}
        onChange={handleChange}
        required
      >
        <option value=""></option>
        <option value="Entrepreneur">Entrepreneur</option>
        <option value="Employment">Employment</option>
      </select>
    </div>

    <button type="submit" className="submit-button">
      Submit
    </button>
  </form>
  )
}
